import React, { useContext, useEffect, useState } from 'react'
import Aside from '../../Layout/Aside'
import Navbar from '../../Layout/Navbar'
import Footer from '../../Layout/Footer'
import { MainContext } from '../../Store/Context'
import Axios from 'axios'
import { apiURL } from '../../Layout/Common/confiqurl'
import { Toaster, toast } from 'react-hot-toast'
import "./style.css"
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Modal, Form } from "react-bootstrap";

import { Icons } from '../../Icons/IconIndex'

const Booking_Partnertable = () => {
    const { user } = useContext(MainContext)
    const [isToastOpen, setIsToastOpen] = useState(false);

    const [state, setState] = useState([])

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);


    const [openpopupView1, setopenpopupView1] = useState(false);


    const [editedData, setEditedData] = useState({});
    const [viewValue, setviewValue] = useState()
    const [editeValue, setEditValue] = useState()
    const [selectedBooking, setSelectedBooking] = useState({})

    // delete state
    const [openpopupDelete, setopenpopupDelete] = useState(false);
    const [deleteValue, setDeleteValue] = useState()

    const [itemsPerPage, setItemsPerPage] = useState(5);
    useEffect(() => {
        getData()
    }, []);

    const [data, setData] = useState()
    const [datas, setDatas] = useState()


    const [sortConfig, setSortConfig] = useState(
        { key: 'place_name', direction: 'descending' },
        { key: 'event_type', direction: 'descending' },
        { key: 'start_date', direction: 'descending' },
        { key: 'end_date', direction: 'descending' },
        { key: 'start_time', direction: 'descending' },
        { key: 'end_time', direction: 'descending' },
        { key: 'contact_number', direction: 'descending' },
        { key: 'name', direction: 'descending' },
        { key: 'active_status', direction: 'descending' }
    );


    const getData = () => {
        const project = JSON.parse(sessionStorage.getItem('data'));


        Axios.get(`${apiURL}/Booking_event?user_id=${project.user_id}`, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,

                "accept": "application/json",
                "Content-Type": " application/json",
            }

        }).then((response) => {
            console.log("api response for cancelled admin table***", response.data)

            const { Booking, Enquiry } = response.data;

            const typedBookings = Booking.map(item => ({ ...item, type: 'booking' }));
            const typedEnquiries = Enquiry.map(item => ({ ...item, type: 'enquiry' }));
            console.log('Bookings***:', typedBookings);
            console.log('Enquiries*88:', typedEnquiries);

            setState([...typedBookings, ...typedEnquiries]);



        })
            .catch((err) => {
                {
                    // setIsLoading(false);
                    console.log(err)
                }
            });
    }



    const handleToggleStatus = (id, itemMode) => {
        console.log(id);

        if (itemMode === 'enquiry') {
            toast.error("Cannot Edit Toggle Status For Enquiries.");
            return;
        }

        const updatedData = state.map((row) => {
            if (row.id === id) {
                setData(!row.active_status);
                handleEdit(!row.active_status, id);

                return { ...row, active_status: !row.active_status };
            }
            return row;
        });
        setState(updatedData);
    };





    const filteredData = Array.isArray(state)
        ? state.filter((item) => {
            const itemValues = Object.values(item).join('').toLowerCase();
            return itemValues.includes(searchQuery.toLowerCase());
        })
        : [];

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = filteredData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const handleClick = (page) => {
        setCurrentPage(page);

    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page when items per page changes
    };

    const pages = Math.ceil(filteredData.length / itemsPerPage);
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(pages, startPage + 4);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, filteredData.length - 1);

    const displayedItems = sortedData.slice(startIndex, endIndex + 1);
    console.log(startIndex, "m", endIndex)
    const paginationButtons = [];
    for (let i = startPage; i <= endPage; i++) {
        paginationButtons.push(
            <li key={i} className={i === currentPage ? "active" : ""}>
                <a
                    key={i}
                    className='active'
                    onClick={() => handleClick(i)}

                    style={{ cursor: "pointer" }}
                >
                    {i}
                </a>
            </li>
        );
    }



    const handleEdit = (value, id) => {
        const project = JSON.parse(sessionStorage.getItem('data'));
        const payload = {
            active_status: value,
        }

        if (!isToastOpen) {
            setIsToastOpen(true);

            Axios.put(`${apiURL}/cancelled_status_in_booking_enquiry?flag=booking&id=${id}`, payload, {
                headers: {
                    'Authorization': `Bearer ${project.access_token}`,
                    "accept": "application/json",
                    "Content-Type": " application/json"
                }
            }).then((response) => {
                toast.success('Successfully Updated');
                getData();
            }).catch((err) => {
                toast.error("Invalid User");
            });

            setTimeout(() => {
                setIsToastOpen(false);
            }, 3000);
        }
    }


    // edit api 

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setSelectedBooking((prevData) => ({ ...prevData, [name]: value }));

    };



    const handleView = (id) => {

        const token = JSON.parse(sessionStorage.getItem('data'));





        const project = JSON.parse(sessionStorage.getItem('data'));



        const queryString = Object.keys(selectedBooking).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(selectedBooking[key])}`).join('&');


        console.log("njcjdajdbsadbajsd*****", id)



        axios.put(`${apiURL}/Booking_Event_Update/${id}?${queryString}`, null, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })

            .then((res) => {
                console.log("edit api response", res.data)

                setSelectedBooking(res.data);
                getData();

            })
            .catch((error) => {
                console.error("Error fetching booking details:", error);
            });

    }

    const handleView1 = (id) => {

        const token = JSON.parse(sessionStorage.getItem('data'));






        const project = JSON.parse(sessionStorage.getItem('data'));
        // Check if selectedBooking is undefined or null


        const queryString = Object.keys(selectedBooking).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(selectedBooking[key])}`).join('&');





        axios.put(`${apiURL}/Booking_Event_Update/${id}?${queryString}`, null, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })

            .then((res) => {
                console.log("edit api response", res.data)
                // if (res.status === 200) {
                toast.success("Updated Data Successfully");
                setSelectedBooking(res.data);
                getData();
                closeModalView1(id);
                // }
            })
            .catch((error) => {
                console.error("Error fetching booking details:", error);
            });
    }

    const openModalView1 = (id) => {
        handleView(id);
        setEditedData({ ...data });
        setopenpopupView1(true);
        setviewValue(id)
        setEditValue(id)
    }
    const closeModalView1 = (id) => {

        setopenpopupView1(false);

    };

    const openModalDelete = (id) => {
        setopenpopupDelete(true);
        setDeleteValue(id)
    }

    //delete api call

    const getDatadelete = () => {
        const id = deleteValue;




        const project = JSON.parse(sessionStorage.getItem('data')); 

        Axios.delete(`${apiURL}/Booking_Event_Delete?id=${id}`, {
            headers: {
                'Authorization': `Bearer ${project.access_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                console.log("responseDataDeleteApi", response.data);
                if (response.data.detail === "Booking Event Deleted Successfully") {
                    toast.success("Booking Event Deleted Successfully");
                    getData(); // Assuming you have a function to refresh your data
                    setopenpopupDelete(false);
                    // closeModalDelete()
                }
            })
            .catch(error => {
                console.error('Error deleting data:', error);
                toast.error("Error deleting booking detail.");
            });

    };
    const closeModalDelete = () => {
        setopenpopupDelete(false);
    };


    return (
        <div  >
            <Toaster toastOptions={{ position: "top-right" }} />
            <div className="container-fluid" style={{ minHeight: "560px" }}>
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="card">

                            {/* <div className="card-header mt-2">
                                    <Link to="/ManageBooking" className=" all_book">
                                        <FontAwesomeIcon
                                            icon={faArrowLeft} /> All Booking
                                    </Link>


                                    <p className="text-sm mb-0">
                                       </p>
                                </div> */}
                            <div className="table-responsive">
                                <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                    <div className="dataTable-top">
                                        <div className="dataTable-dropdown">
                                            <label style={{ width: "300px" }}>
                                                <select className="dataTable-selector w-50 me-2"
                                                    onChange={handleItemsPerPageChange}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <option value="5" style={{ cursor: "pointer" }}>5</option>
                                                    <option value="10 " style={{ cursor: "pointer" }}>10</option>
                                                    <option value="15" style={{ cursor: "pointer" }}>15</option>
                                                    <option value="20" style={{ cursor: "pointer" }}>20</option>
                                                    <option value="25" style={{ cursor: "pointer" }}>25</option>
                                                </select>
                                                entries per page</label>
                                        </div>
                                        <div className="dataTable-search">
                                            <input className="dataTable-input" placeholder="Search..." type="text" value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)} />
                                        </div>
                                    </div>
                                    <div className="dataTable-container table-responsive w-100" >
                                        <table className="table  " id="datatable-search">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th data-sortable="" >
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767",cursor:'pointer'  }} onClick={() => handleSort('place_name')}>Place Name {sortConfig.key === 'place_name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                                                    </th>
                                                    <th data-sortable="" >
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767",cursor:'pointer'  }} onClick={() => handleSort('event_type')}>Event Type {sortConfig.key === 'event_type' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                                                    </th>
                                                    <th data-sortable="" style={{ width: "18.18577%" }}>
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767" ,cursor:'pointer' }} onClick={() => handleSort('start_date')}> Start Date {sortConfig.key === 'start_date' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                                                    </th>
                                                    <th data-sortable="" >
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767",cursor:'pointer'  }} onClick={() => handleSort('end_date')}>End Date {sortConfig.key === 'end_date' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                                                    </th>
                                                    <th data-sortable="" >
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767",cursor:'pointer'  }} onClick={() => handleSort('start_time')}> Start Time {sortConfig.key === 'start_time' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                                                    </th>
                                                    <th data-sortable="" >
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767" ,cursor:'pointer' }} onClick={() => handleSort('end_time')}> End Time {sortConfig.key === 'end_time' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                                                    </th>
                                                    <th data-sortable="" >
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767",cursor:'pointer'  }} onClick={() => handleSort('contact_number')}> Contact number {sortConfig.key === 'contact_number' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                                                    </th>
                                                    <th data-sortable="" >
                                                        <a  className=" " style={{ fontSize: "12px", color: "#344767" ,cursor:'pointer' }} onClick={() => handleSort('email_id')}> EmailID {sortConfig.key === 'email_id' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                                                    </th>
                                                    <th data-sortable="" >
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767", cursor:'pointer' }} onClick={() => handleSort('name')}> Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                                                    </th>
                                                    <th data-sortable="" >
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767",cursor:'pointer'  }} onClick={() => handleSort('type')}>Mode {sortConfig.key === 'type' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                                                    </th>
                                                    <th data-sortable="" >
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767",cursor:'pointer' }} onClick={() => handleSort('active_status')}> Status {sortConfig.key === 'active_status' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</a>
                                                    </th>
                                                    <th data-sortable="" >
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767" }}> Action 1</a>
                                                    </th>
                                                    <th data-sortable="" >
                                                        <a  className="" style={{ fontSize: "12px", color: "#344767" }}> Action 2</a>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {displayedItems && displayedItems.map((item, index) => {






                                                    return (<tr className=''>
                                                        <td className="text-sm font-weight-normal " style={{ fontSize: "10px" }}>{item.place_name}</td>
                                                        <td className="text-sm font-weight-normal" style={{ whiteSpace: 'normal' }} >{item.event_type}</td>
                                                        <td className="text-sm font-weight-normal" style={{ fontSize: "10px" }}>{item.start_date}</td>
                                                        <td className="text-sm font-weight-normal" style={{ fontSize: "10px" }}>{item.end_date}</td>
                                                        <td className="text-sm font-weight-normal" style={{ fontSize: "10px" }}>{item.start_time}</td>
                                                        <td className="text-sm font-weight-normal" style={{ fontSize: "10px" }}>{item.end_time}</td>
                                                        <td className="text-sm font-weight-normal" style={{ fontSize: "10px" }}>{item.contact_number}</td>

                                                        <td className="text-sm font-weight-normal" style={{ fontSize: "10px" }}>{item.email_id}</td>




                                                        <td className="text-sm font-weight-normal" style={{ fontSize: "10px" }}>{item.name}</td>

                                                        <td className={`text-sm font-weight-normal ${item.type === 'booking' ? 'text-blue' : 'text-red'}`}
                                                            style={{ fontSize: "10px" }}>
                                                            {item.type === 'booking' ? <span style={{ color: 'green', fontWeight: '700', fontSize: '16px', }}>Booking</span> : <span style={{ color: 'blue', fontSize: '16px', fontWeight: '700' }}>Enquiry</span>}
                                                        </td>


                                                        <td className="text-sm font-weight-normal" style={{ fontSize: "10px" }}>
                                                            {item.active_status ? (<span className="badge badge-success badge-sm"> Confirmed</span>) : (<span className="badge badge-danger badge-sm">Not Confirmed</span>)}
                                                            {/* {item.active_status} */}
                                                        </td>

                                                        <td className="text-sm font-weight-normal">    <div className="form-check form-switch pt-1 ms-3"


                                                        >


                                                            {/*    <input className="form-check-input" type="checkbox" id="rememberMe" checked={item.active_status}
                                                                 onClick={() => handleToggleStatus(item.id)} />*/}


                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`toggle-${item.id}`}
                                                                checked={item.active_status}
                                                                onChange={() => handleToggleStatus(item.id)}
                                                                disabled={item.type === 'enquiry'} // Disable if in enquiry mode
                                                            />
                                                        </div></td>
                                                        <td className="text-sm text-sm font-weight-normal"  >
                                                            <a data-bs-toggle="tooltip" data-bs-original-title="Preview product"

                                                            >

                                                            </a>

                                                            <a
                                                                data-bs-toggle="tooltip"
                                                                className={`mx-3 ${item.type === 'enquiry' ? 'disabled' : ''}`} // Apply 'disabled' class if in enquiry mode
                                                                data-bs-original-title="Edit Hall"
                                                                onClick={() => {
                                                                    if (item.type !== 'enquiry') { // Prevent click action if in enquiry mode

                                                                        openModalView1(item.id);
                                                                        handleView(item.id);
                                                                    }
                                                                }}
                                                            >
                                                                <i className="text-secondary" aria-hidden="true">
                                                                    <FontAwesomeIcon icon={Icons.faPen} />
                                                                </i>
                                                            </a>
                                                            <a data-bs-toggle="tooltip"
                                                                className={` ${item.type === 'enquiry' ? 'disabled' : ''}`}
                                                                data-bs-original-title="Delete Hall"
                                                            // onClick={() => openModalDelete(item.user_id)}/
                                                            >
                                                                <i className="text-secondary" aria-hidden="true">
                                                                    <FontAwesomeIcon icon={Icons.faTrash}
                                                                        onClick={() => {
                                                                            if (item.type !== 'enquiry') {
                                                                                openModalDelete(item.id)
                                                                            }
                                                                        }} />
                                                                </i>
                                                            </a>
                                                        </td>

                                                    </tr>)
                                                }
                                                )}





                                            </tbody>
                                        </table>

                                    </div>

                                    {/* // edit popup start here */}

                                    <Modal show={openpopupView1} size='lg' className='model-popup-design' >
                                        <div className='d-flex justify-content-between mt-3' >
                                            <Modal.Title className='ms-4 '>Update Booking Event Details</Modal.Title>
                                            <button className='me-3 btn btn-outline-secondary'
                                                onClick={closeModalView1}
                                            >
                                                x
                                            </button>
                                        </div>
                                        <div className='ms-4'>
                                        </div>
                                        <div className='loginmodalbody' style={{ width: 'auto' }}>
                                            <Modal.Body className='testing-test'>


                                                <Form.Group >
                                                    <Form.Label>
                                                        Name:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        // onChange={handleChange1}
                                                        onChange={(e) => setSelectedBooking({ ...selectedBooking, name: e.target.value })}
                                                        value={selectedBooking ? selectedBooking.name : ''}
                                                        placeholder="Name"
                                                    />
                                                </Form.Group>
                                                <Form.Group >
                                                    <Form.Label>
                                                        Email:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        name="email_id"
                                                        onChange={(e) => setSelectedBooking({ ...selectedBooking, email_id: e.target.value })}
                                                        value={selectedBooking ? selectedBooking.email_id : ''}
                                                        placeholder="Email"
                                                    />
                                                </Form.Group>
                                                <Form.Group >
                                                    <Form.Label>
                                                        Contact No:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="contact_number"
                                                        onChange={(e) => setSelectedBooking({ ...selectedBooking, contact_number: e.target.value })}
                                                        value={selectedBooking ? selectedBooking.contact_number : ''}
                                                        placeholder="Contact No"
                                                        required
                                                    />
                                                </Form.Group>
                                                <Form.Group >
                                                    <Form.Label>
                                                        Event_Type:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="event_type"
                                                        onChange={(e) => setSelectedBooking({ ...selectedBooking, event_type: e.target.value })}
                                                        value={selectedBooking ? selectedBooking.event_type : ''}
                                                        placeholder="Event Type"
                                                    />
                                                </Form.Group>
                                                <Form.Group >
                                                    <Form.Label>
                                                        Start_Time:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="time"
                                                        name="start_time"
                                                        onChange={(e) => setSelectedBooking({ ...selectedBooking, start_time: e.target.value })}
                                                        value={selectedBooking ? selectedBooking.start_time : ''}
                                                        placeholder="Start Time"
                                                    />
                                                </Form.Group>
                                                <Form.Group >
                                                    <Form.Label>
                                                        End_Time:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="time"
                                                        name="end_time"
                                                        onChange={(e) => setSelectedBooking({ ...selectedBooking, end_time: e.target.value })}
                                                        value={selectedBooking ? selectedBooking.end_time : ''}
                                                        placeholder="End Time"
                                                    />
                                                </Form.Group>
                                                <Form.Group >
                                                    <Form.Label>
                                                        Start_Date:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="start_date"
                                                        onChange={(e) => setSelectedBooking({ ...selectedBooking, start_date: e.target.value })}
                                                        value={selectedBooking ? selectedBooking.start_date : ''}
                                                        placeholder="Start Date"
                                                        onFocus={(e) => e.target.showPicker()}
                                                    />
                                                </Form.Group>
                                                <Form.Group >
                                                    <Form.Label>
                                                        End_Date:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="end_date"
                                                        onChange={(e) => setSelectedBooking({ ...selectedBooking, end_date: e.target.value })}
                                                        value={selectedBooking ? selectedBooking.end_date : ''}
                                                        placeholder="End Date"
                                                        onFocus={(e) => e.target.showPicker()}
                                                    />
                                                </Form.Group>

                                            </Modal.Body>
                                        </div>
                                        <Modal.Footer>
                                            <Button variant="secondary" style={{
                                                backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)',
                                                color: 'white',
                                                border: 'none',
                                            }} onClick={() => handleView1(editeValue)}>
                                                update
                                            </Button>
                                            <Button variant="secondary" style={{
                                                backgroundColor: 'white',
                                                color: '#cb0c9f',
                                                border: '1px solid #cb0c9f',
                                            }} onClick={closeModalView1}>
                                                Cancel
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal show={openpopupDelete} size='md' className='model-popup-design' >
                                        <div className='d-flex justify-content-between mt-3' >
                                            <Modal.Title className='ms-4 '>Delete</Modal.Title>
                                            <button className='me-3 btn btn-outline-secondary  ' onClick={closeModalDelete}>
                                                x
                                            </button>
                                        </div>
                                        <div className='loginmodalbody' style={{ width: '785px' }}>
                                            <Modal.Body className='testing-test' >
                                                Are you sure you want to delete this item ?
                                            </Modal.Body>
                                        </div>
                                        <Modal.Footer>
                                            <Button variant="danger" style={{
                                                backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)',
                                                color: 'white',
                                                border: 'none',
                                            }} onClick={getDatadelete}>
                                                Delete
                                            </Button>
                                            <Button variant="secondary" style={{
                                                backgroundColor: 'white',
                                                color: '#cb0c9f',
                                                border: '1px solid #cb0c9f',
                                            }} onClick={closeModalDelete}>
                                                Cancel
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <div className="dataTable-bottom">
                                        <div className="dataTable-info">Showing {startIndex + 1} to  {currentPage === displayedItems.length ? filteredData.length : endIndex + 1}   of {filteredData.length} entries</div>
                                        <nav className="dataTable-pagination">



                                            <ul className="dataTable-pagination-list">
                                                <li className="pager" style={{ cursor: "pointer" }}>

                                                    <a onClick={() => handleClick(currentPage - 1)}
                                                        className={currentPage === 1 ? "button-disable" : ""} 
                                                    > ‹ </a>
                                                </li> 
                                                {paginationButtons} 
                                                <li className="pager" style={{ cursor: "pointer" }}><a data-page="2"
                                                    onClick={() => handleClick(currentPage + 1)} 
                                                    className={currentPage === pages ? "button-disable" : ""}
                                                >›</a></li> 
                                            </ul></nav>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default Booking_Partnertable